import { Drawer } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { ReactNode } from "react";
import { DialogAppBar, TicketActionNavigationProps } from "./DialogAppBar";
import { useWindowSize } from "@uidotdev/usehooks";

export function ResponsiveDrawer({
  open,
  onClose,
  children,
  title,
  renderDialogAppBarRight,
  fullscreenOverride,
}: {
  title: string;
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  renderDialogAppBarRight?: () => ReactNode;
  fullscreenOverride?: boolean;
} & TicketActionNavigationProps) {
  const windowSize = useWindowSize();
  const drawerMaxWidth = Math.min(700, windowSize.width);
  const fullScreen = windowSize.width <= drawerMaxWidth;

  if (fullScreen || fullscreenOverride) {
    return (
      <Dialog
        open={open}
        onClose={onClose}
        fullScreen={fullScreen || fullscreenOverride}
      >
        <DialogAppBar
          onCloseClick={onClose}
          title={title}
          renderRight={renderDialogAppBarRight}
        />
        {children}
      </Dialog>
    );
  }

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="right"
      PaperProps={{
        style: {
          width: drawerMaxWidth,
        },
      }}
    >
      <DialogAppBar
        onCloseClick={onClose}
        title={title}
        renderRight={renderDialogAppBarRight}
      />
      {children}
    </Drawer>
  );
}
