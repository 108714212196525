import { LinkProps } from "@mui/material/Link";
import { deepmerge } from "@mui/utils";
import { Breakpoint, createTheme, ThemeOptions } from "@mui/material";

import { createTypography } from "./theme/create-typography";
import getValueForBreakpoint from "./getValueForBreakpoint";
import { defaultDataGridLocaleText } from "@components/common/datagrid/defaultDataGridLocaleText";
import { RouterLink } from "@components/common/link/RouterLink";
import { PaletteOptions } from "@mui/material/styles";

export type CreateAppThemeParams = {
  breakpoint: Breakpoint;
  mode: "dark" | "light";
  themeOptions?: ThemeOptions;
};

export function createAppTheme({
  mode,
  breakpoint,
  themeOptions = {},
}: CreateAppThemeParams) {
  const spacing = getValueForBreakpoint({
    breakpoint,
    breakpointValues: {
      xs: 4,
      sm: 8,
    },
  });

  const isDarkMode = mode === "dark";
  const palette: PaletteOptions = {
    mode,
    common: {
      black: "#222",
      white: "fff",
    },
    text: {
      primary: isDarkMode ? "#fff" : "#222",
    },
    primary: {
      main: isDarkMode ? "#6297ba" : "#163b55",
    },
    secondary: {
      main: isDarkMode ? "#8ad4de" : "rgb(127,184,199)",
    },
    background: {
      default: isDarkMode ? "#121212" : "#f5f5f5",
      paper: isDarkMode ? "#1e1e1e" : "#FFFEFA",
    },
  };

  return createTheme(
    deepmerge(
      {
        spacing,
        palette: palette,
        typography: createTypography(),
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                backgroundColor: "rgba(0, 0, 0, 0.82)",
                color: "#fff",
                fontSize: "1rem",
              },
            },
          },
          MuiDataGrid: {
            defaultProps: {
              localeText: defaultDataGridLocaleText,
              pageSizeOptions: [5, 10, 25, 50, 100],
              pagination: true,
            },
            styleOverrides: {
              root: {
                "& .MuiDataGrid-row.Mui-selected": {
                  backgroundColor: "rgba(239,241,243,0.20)",
                },
                "& .MuiDataGrid-cell": {
                  padding: "4px",
                },
              },
            },
          },
          MuiIconButton: {
            defaultProps: {
              color: "secondary",
              size: "small",
            },
          },
          MuiSvgIcon: {
            defaultProps: {
              sx: {
                fontSize: "1.5rem",
              },
            },
          },
          MuiTextField: {
            defaultProps: {
              size: "small",
              fullWidth: true,
            },
          },
          MuiLink: {
            defaultProps: {
              component: RouterLink,
              sx: {
                color: isDarkMode ? "#6297ba" : undefined,
              },
            } as LinkProps,
          },
          MuiButtonBase: {
            defaultProps: {
              LinkComponent: RouterLink,
            },
          },
        },
      },
      themeOptions,
    ),
  );
}
