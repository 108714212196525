import { gettext, interpolate } from "@services/i18n";
import { GridLocaleText } from "@mui/x-data-grid-pro";
import { MuiTablePaginationLocalizedProps } from "@mui/x-data-grid/models/api/gridLocaleTextApi";

const pagination: MuiTablePaginationLocalizedProps = {
  labelRowsPerPage: gettext("Rows per page"),
};

export const defaultDataGridLocaleText: Partial<GridLocaleText> = {
  toolbarExportPrint: gettext("Print"),
  "filterOperator!=": gettext("is not equal"),
  "filterOperator<": gettext("is less than"),
  "filterOperator<=": gettext("is less than or equal to"),
  "filterOperator=": gettext("is equal"),
  "filterOperator>": gettext("is greater than"),
  "filterOperator>=": gettext("is greater than or equal to"),
  "headerFilterOperator!=": gettext("is not equal"),
  "headerFilterOperator<": gettext("is less than"),
  "headerFilterOperator<=": gettext("is less than or equal to"),
  "headerFilterOperator=": gettext("is equal"),
  "headerFilterOperator>": gettext("is greater than"),
  "headerFilterOperator>=": gettext("is greater than or equal to"),
  actionsCellMore: gettext("More actions"),
  aggregationFunctionLabelAvg: gettext("Average"),
  aggregationFunctionLabelMax: gettext("Maximum"),
  aggregationFunctionLabelMin: gettext("Minimum"),
  aggregationFunctionLabelSize: gettext("Size"),
  aggregationFunctionLabelSum: gettext("Sum"),
  aggregationMenuItemHeader: gettext("Aggregation"),
  booleanCellFalseLabel: gettext("False"),
  booleanCellTrueLabel: gettext("True"),
  checkboxSelectionHeaderName: gettext("Checkbox Selection"),
  checkboxSelectionSelectAllRows: gettext("Select all rows"),
  checkboxSelectionSelectRow: gettext("Select row"),
  checkboxSelectionUnselectAllRows: gettext("Unselect all rows"),
  checkboxSelectionUnselectRow: gettext("Unselect row"),
  collapseDetailPanel: gettext("Collapse"),
  columnHeaderFiltersLabel: gettext("Filters"),
  columnHeaderFiltersTooltipActive: (count) =>
    interpolate(gettext("%s active filters"), [count]),
  columnHeaderSortIconLabel: gettext("Sort"),
  columnMenuFilter: gettext("Filter"),
  columnMenuHideColumn: gettext("Hide column"),
  columnMenuLabel: gettext("Column options"),
  columnMenuManageColumns: gettext("Manage columns"),
  columnMenuShowColumns: gettext("Show columns"),
  columnMenuUnsort: gettext("Unsort"),
  columnsManagementDeleteIconLabel: gettext("Delete"),
  columnsManagementNoColumns: gettext("No columns"),
  columnsManagementReset: gettext("Reset"),
  columnsManagementSearchTitle: gettext("Search"),
  columnsManagementShowHideAllText: gettext("Show/Hide All"),
  detailPanelToggle: gettext("Toggle detail panel"),
  expandDetailPanel: gettext("Expand"),
  filterOperatorAfter: gettext("is after"),
  filterOperatorBefore: gettext("is before"),
  filterOperatorContains: gettext("contains"),
  filterOperatorDoesNotContain: gettext("does not contain"),
  filterOperatorDoesNotEqual: gettext("does not equal"),
  filterOperatorEndsWith: gettext("ends with"),
  filterOperatorEquals: gettext("equals"),
  filterOperatorIs: gettext("is"),
  filterOperatorIsAnyOf: gettext("is any of"),
  filterOperatorIsEmpty: gettext("is empty"),
  filterOperatorIsNotEmpty: gettext("is not empty"),
  filterOperatorNot: gettext("not"),
  filterOperatorOnOrAfter: gettext("on or after"),
  filterOperatorOnOrBefore: gettext("on or before"),
  filterOperatorStartsWith: gettext("starts with"),
  filterPanelAddFilter: gettext("Add filter"),
  filterPanelColumns: gettext("Columns"),
  filterPanelDeleteIconLabel: gettext("Delete filter"),
  filterPanelInputLabel: gettext("Value"),
  filterPanelInputPlaceholder: gettext("Enter value"),
  filterPanelLogicOperator: gettext("Logic operator"),
  filterPanelOperator: gettext("Operator"),
  filterPanelOperatorAnd: gettext("And"),
  filterPanelOperatorOr: gettext("Or"),
  filterPanelRemoveAll: gettext("Remove all filters"),
  filterValueAny: gettext("Any"),
  filterValueFalse: gettext("False"),
  filterValueTrue: gettext("True"),
  footerTotalRows: gettext("Total rows"),
  footerTotalVisibleRows: (visibleCount, totalCount) =>
    interpolate(gettext("%s of %s rows visible"), [visibleCount, totalCount]),
  groupColumn: (name) => interpolate(gettext("Group by %s"), [name]),
  groupingColumnHeaderName: gettext("Group"),
  headerFilterOperatorAfter: gettext("is after"),
  headerFilterOperatorBefore: gettext("is before"),
  headerFilterOperatorContains: gettext("contains"),
  headerFilterOperatorDoesNotContain: gettext("does not contain"),
  headerFilterOperatorDoesNotEqual: gettext("does not equal"),
  headerFilterOperatorEndsWith: gettext("ends with"),
  headerFilterOperatorEquals: gettext("equals"),
  headerFilterOperatorIs: gettext("is"),
  headerFilterOperatorIsAnyOf: gettext("is any of"),
  headerFilterOperatorIsEmpty: gettext("is empty"),
  headerFilterOperatorIsNotEmpty: gettext("is not empty"),
  headerFilterOperatorNot: gettext("not"),
  headerFilterOperatorOnOrAfter: gettext("on or after"),
  headerFilterOperatorOnOrBefore: gettext("on or before"),
  headerFilterOperatorStartsWith: gettext("starts with"),
  noResultsOverlayLabel: gettext("No results found"),
  pinToLeft: gettext("Pin to left"),
  pinToRight: gettext("Pin to right"),
  rowReorderingHeaderName: gettext("Reorder"),
  toolbarColumnsLabel: gettext("Columns"),
  toolbarDensityComfortable: gettext("Comfortable"),
  toolbarDensityCompact: gettext("Compact"),
  toolbarDensityLabel: gettext("Density"),
  toolbarDensityStandard: gettext("Standard"),
  toolbarExport: gettext("Export"),
  toolbarExportCSV: gettext("Export as CSV"),
  toolbarExportExcel: gettext("Export as Excel"),
  toolbarExportLabel: gettext("Export options"),
  toolbarFiltersLabel: gettext("Filters"),
  toolbarFiltersTooltipActive: (count) =>
    interpolate(gettext("%s active filters"), [count]),
  toolbarFiltersTooltipHide: gettext("Hide filters"),
  toolbarFiltersTooltipShow: gettext("Show filters"),
  toolbarQuickFilterDeleteIconLabel: gettext("Clear filter"),
  toolbarQuickFilterLabel: gettext("Quick filter"),
  toolbarQuickFilterPlaceholder: gettext("Search..."),
  treeDataCollapse: gettext("Collapse tree"),
  treeDataExpand: gettext("Expand tree"),
  treeDataGroupingHeaderName: gettext("Grouping"),
  unGroupColumn: (name) => interpolate(gettext("Ungroup %s"), [name]),
  unpin: gettext("Unpin"),
  toolbarColumns: gettext("Columns"),
  toolbarFilters: gettext("Filters"),
  toolbarDensity: gettext("Density"),
  noRowsLabel: gettext(
    "No records found. Use the searchbar to find and add records.",
  ),
  footerRowSelected: (count) =>
    interpolate(gettext("%s records selected"), [count]),
  columnMenuSortAsc: gettext("Sort ascending"),
  columnMenuSortDesc: gettext("Sort descending"),
  MuiTablePagination: pagination,
};
